@import "xa-image/dist/Components/Img/Img.css";
html {
  height: 100%;
  box-sizing: border-box;
  background-color: transparent;
  margin: 0;
  padding: 0;
  font-size: 10px;
}

body {
  box-sizing: border-box;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: relative;
  margin: 0;
  min-height: 100%;
  background-color: #ffffff;
  color: #171717;
  font-size: 1.4rem;
  overscroll-behavior: none;
  -webkit-overflow-scrolling: none;
}

::-webkit-scrollbar {
  width: 0.4rem !important;
}

#root {
  min-height: 100vh;
}

*,
*::before,
*::after {
  -webkit-overflow-scrolling: touch;
  box-sizing: inherit;
  border: none;
  outline: none;
}
*:focus, *:focus-visible,
*::before:focus,
*::before:focus-visible,
*::after:focus,
*::after:focus-visible {
  outline: none;
}

.spacing {
  width: 100%;
  height: 1.6rem;
}

.no-data {
  padding: 1.6rem;
  width: 100%;
  display: block;
  text-align: center;
  background-color: #e0e7e9;
  color: #6a6a6a;
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Old versions of Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently supported by Chrome, Opera and Firefox */
}

address {
  font-style: normal;
}

button {
  padding: 0;
  margin: 0;
  cursor: pointer;
}

button,
div {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

a {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  text-decoration: unset;
  cursor: pointer;
  color: unset;
}
a:hover {
  -webkit-filter: brightness(110%);
  -ms-filter: brightness(110%);
  filter: brightness(110%);
}
a:active, a:visited {
  -webkit-filter: brightness(110%);
  -ms-filter: brightness(110%);
  filter: brightness(110%);
}

::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

::-webkit-scrollbar-track {
  background: #ffffff;
}

::-webkit-scrollbar-thumb {
  background: #757575;
  -webkit-border-radius: 15px;
  -moz-border-radius: 15px;
  border-radius: 15px;
}

::-webkit-scrollbar-thumb:hover {
  background: #525252;
}

.global__spacer {
  -webkit-transition: padding-bottom 100ms linear;
  -moz-transition: padding-bottom 100ms linear;
  -ms-transition: padding-bottom 100ms linear;
  -o-transition: padding-bottom 100ms linear;
  transition: padding-bottom 100ms linear;
}
.global__error-wrapper {
  padding: 0 1.2rem 0.8rem 1.2rem;
  margin-top: -0.8rem;
}
.global__error {
  color: #fb3350;
  padding-top: 0.4rem;
  font-size: 10px;
  text-align: right;
}
.global__space-after {
  margin-bottom: 0.8rem;
}
.global__link {
  display: flex;
  cursor: pointer;
  text-align: center;
  color: #a93e73;
  justify-content: center;
  align-items: center;
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Old versions of Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently supported by Chrome, Opera and Firefox */
}
.global__link:hover {
  text-decoration: underline;
}
.global__view-image__header {
  height: 4rem;
  display: grid;
  align-items: stretch;
  background-color: #e7ecef;
  grid-template-columns: auto 4rem;
}
.global__view-image__header--text {
  display: flex;
  text-align: left;
  font-weight: 500;
  align-items: center;
  justify-content: flex-start;
  padding: 0.4rem 1.6rem;
  font-size: 1.6rem;
}
.global__view-image__header--control {
  cursor: pointer;
  align-items: center;
  display: inline-flex;
  justify-content: center;
}
.global__view-image__header--control:hover {
  background-color: #ffffff;
}
.global__view-image__body {
  overflow: auto;
  max-width: 80vw;
  max-height: 80vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
.global__view-image__body--image {
  max-width: 100%;
  max-height: 100%;
  width: auto;
  height: auto;
}
@media screen and (max-width: 576px) {
  .global__view-image__body {
    max-width: 98vw;
    max-height: 90vh;
  }
}
.global__rounded-corners {
  -webkit-border-radius: 1rem;
  -moz-border-radius: 1rem;
  border-radius: 1rem;
}
.global__rounded-corners .select,
.global__rounded-corners .common-input,
.global__rounded-corners .select-input,
.global__rounded-corners .datepicker-input,
.global__rounded-corners .select-input__search {
  -webkit-border-radius: 1rem;
  -moz-border-radius: 1rem;
  border-radius: 1rem;
}
.global__rounded-corners .text-input-unit,
.global__rounded-corners .clear {
  -webkit-border-top-right-radius: 1rem;
  -webkit-border-bottom-right-radius: 1rem;
  -webkit-border-bottom-left-radius: 0;
  -webkit-border-top-left-radius: 0;
  -moz-border-radius-topright: 1rem;
  -moz-border-radius-bottomright: 1rem;
  -moz-border-radius-bottomleft: 0;
  -moz-border-radius-topleft: 0;
  border-top-right-radius: 1rem;
  border-bottom-right-radius: 1rem;
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
  -moz-background-clip: padding;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
}
.global__rounded-corners .check-mark {
  -webkit-border-radius: 1rem;
  -moz-border-radius: 1rem;
  border-radius: 1rem;
}
.global__rounded-corners .date-popper {
  -webkit-border-radius: 1rem;
  -moz-border-radius: 1rem;
  border-radius: 1rem;
}
.global__rounded-corners .date-popper__control {
  -webkit-border-top-right-radius: 1rem;
  -webkit-border-bottom-right-radius: 0;
  -webkit-border-bottom-left-radius: 0;
  -webkit-border-top-left-radius: 1rem;
  -moz-border-radius-topright: 1rem;
  -moz-border-radius-bottomright: 0;
  -moz-border-radius-bottomleft: 0;
  -moz-border-radius-topleft: 1rem;
  border-top-right-radius: 1rem;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
  border-top-left-radius: 1rem;
  -moz-background-clip: padding;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
}
.global__rounded-corners .date-popper__time-inputs {
  column-gap: 1px;
  -webkit-border-top-right-radius: 0;
  -webkit-border-bottom-right-radius: 1rem;
  -webkit-border-bottom-left-radius: 1rem;
  -webkit-border-top-left-radius: 0;
  -moz-border-radius-topright: 0;
  -moz-border-radius-bottomright: 1rem;
  -moz-border-radius-bottomleft: 1rem;
  -moz-border-radius-topleft: 0;
  border-top-right-radius: 0;
  border-bottom-right-radius: 1rem;
  border-bottom-left-radius: 1rem;
  border-top-left-radius: 0;
  -moz-background-clip: padding;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
}
.global__rounded-corners .date-popper__time-inputs .select:first-of-type .select-input__search {
  -webkit-border-top-right-radius: 0;
  -webkit-border-bottom-right-radius: 0;
  -webkit-border-bottom-left-radius: 1rem;
  -webkit-border-top-left-radius: 0;
  -moz-border-radius-topright: 0;
  -moz-border-radius-bottomright: 0;
  -moz-border-radius-bottomleft: 1rem;
  -moz-border-radius-topleft: 0;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 1rem;
  border-top-left-radius: 0;
  -moz-background-clip: padding;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
}
.global__rounded-corners .date-popper__time-inputs .select:last-of-type .select-input__search, .global__rounded-corners .date-popper__time-inputs .select:last-of-type .select-input__control {
  -webkit-border-top-right-radius: 0;
  -webkit-border-bottom-right-radius: 1rem;
  -webkit-border-bottom-left-radius: 0;
  -webkit-border-top-left-radius: 0;
  -moz-border-radius-topright: 0;
  -moz-border-radius-bottomright: 1rem;
  -moz-border-radius-bottomleft: 0;
  -moz-border-radius-topleft: 0;
  border-top-right-radius: 0;
  border-bottom-right-radius: 1rem;
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
  -moz-background-clip: padding;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
}

.dark-image-viewer .global__view-image__header {
  background-color: #171717;
  color: #e7ecef;
}
.dark-image-viewer .global__view-image__header--control:hover {
  background-color: #333333;
}

.error-container__oneline-text {
  border: 1px solid #fb3350;
  color: #fb3350;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0;
}

h1 {
  font-size: 3.2rem;
  font-weight: 500;
}

h2 {
  font-size: 2.8rem;
  font-weight: 500;
}

h3 {
  font-size: 2.4rem;
  font-weight: 600;
}

h4 {
  font-size: 2rem;
  font-weight: 600;
}

h5 {
  font-size: 1.8rem;
  font-weight: 600;
}

h6 {
  font-size: 1.6rem;
  font-weight: 600;
}

.roboto {
  font-family: "Roboto", sans-serif;
}

.opensans {
  font-family: "Open Sans", sans-serif;
}

.montserrat {
  font-family: "Montserrat", sans-serif;
}

.ubuntu {
  font-family: "Ubuntu", sans-serif;
}

.ibmplexsans {
  font-family: "IBM Plex Sans", sans-serif;
}

.hind {
  font-family: "Hind", sans-serif;
}

.ibmplexmono {
  font-family: "IBM Plex Mono", monospace;
}

.robotomono {
  font-family: "Roboto Mono", monospace;
}

.balsamiq {
  font-family: "Balsamiq Sans", cursive;
}

.arimamadurai {
  font-family: "Arima Madurai", cursive;
}

.teko {
  font-family: "Teko", sans-serif;
}

.nunito {
  font-family: "Nunito", sans-serif;
}

.page-container {
  padding-left: 0;
  padding-right: 0;
  padding-top: 0;
  min-height: 100vh;
  background-color: #ffffff;
  position: relative;
  -webkit-box-shadow: 0 0 7px 4px rgba(0, 0, 0, 0.15);
  -moz-box-shadow: 0 0 7px 4px rgba(0, 0, 0, 0.15);
  box-shadow: 0 0 7px 4px rgba(0, 0, 0, 0.15);
}
@supports not (position: sticky) {
  .page-container {
    padding-top: 92px;
  }
}

.section {
  display: grid;
  position: relative;
  width: 100%;
  min-height: 50px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Old versions of Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently supported by Chrome, Opera and Firefox */
}
.section.special-section {
  min-height: unset;
}
.section.show-as-top-section {
  z-index: 101;
}
.section.section-grid-col-1 {
  grid-template-columns: 100%;
}
.section.section-grid-col-2 {
  grid-template-columns: repeat(2, 1fr);
}
.section.section-grid-col-3 {
  grid-template-columns: repeat(3, 1fr);
}
.section.section-grid-col-4 {
  grid-template-columns: repeat(4, 1fr);
}
.section.section-grid-col-5 {
  grid-template-columns: repeat(5, 1fr);
}
.section.section-xs.section-grid-col-2, .section.section-xs.section-grid-col-3, .section.section-xs.section-grid-col-4, .section.section-xs.section-grid-col-5 {
  grid-template-columns: 1fr;
}
.section.section-sm.section-grid-col-2, .section.section-sm.section-grid-col-3, .section.section-sm.section-grid-col-4, .section.section-sm.section-grid-col-5 {
  grid-template-columns: 1fr;
}
.section.section-md.section-grid-col-4 {
  grid-template-columns: repeat(2, 1fr);
}
.section.section-md.section-grid-col-5 {
  grid-template-columns: repeat(3, 1fr);
}

.column {
  min-height: 50px;
  padding: 2.4rem;
  width: 100%;
  display: inline-block;
  margin: 0;
  position: relative;
}
.column.special-column {
  padding: 0;
  min-height: unset;
}

.dom__qr {
  display: flex;
  justify-content: center;
}
.dom__header {
  width: 100%;
  display: block;
  position: relative;
}
.dom__header .h {
  margin: 0;
  padding-bottom: 1.2rem;
  width: 100%;
}
.dom__header--h1 {
  font-size: 4.2rem;
  font-weight: 500;
}
.dom__header--h2 {
  font-size: 2.8rem;
  font-weight: 500;
}
.dom__header--h3 {
  font-size: 2.4rem;
  font-weight: 500;
}
.dom__header--h4 {
  font-size: 2rem;
  font-weight: 500;
}
.dom__header--h5 {
  font-size: 1.8rem;
  font-weight: 600;
}
.dom__header--h6 {
  font-size: 1.6rem;
  font-weight: 600;
}
.dom__video {
  position: relative;
}
.dom__video--player {
  border: none;
  width: 100%;
  height: 100%;
}
.dom__text {
  width: 100%;
  display: block;
  text-align: left;
  word-break: break-word;
  font-size: 1.6rem;
  position: relative;
  padding-bottom: 1.2rem;
}
.dom__link {
  position: relative;
  color: #29b9ff;
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Old versions of Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently supported by Chrome, Opera and Firefox */
}
.dom__link:hover {
  -webkit-filter: brightness(115%);
  -ms-filter: brightness(115%);
  filter: brightness(115%);
}
.dom__link--button-type {
  padding: 1.2rem;
  cursor: pointer;
  border: none;
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Old versions of Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently supported by Chrome, Opera and Firefox */
}
.dom__link--button-type:hover {
  -webkit-filter: brightness(100%);
  -ms-filter: brightness(100%);
  filter: brightness(100%);
}
.dom__image-container {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}
.dom__image-container--image {
  max-width: 100%;
  height: auto;
  max-height: 100%;
  width: auto;
}
.dom__bullet-list {
  width: 100%;
  display: block;
  position: relative;
}
.dom__button {
  border: none;
  padding: 0.8rem 1.6rem;
  outline: none;
  display: grid;
  align-items: center;
  font-size: 1.4rem;
  grid-template-columns: 100%;
  cursor: pointer;
  background-color: #a93e73;
  color: #e7ecef;
  position: relative;
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Old versions of Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently supported by Chrome, Opera and Firefox */
}
.dom__button:focus {
  outline: none;
  border: none;
}
.dom__button--icons {
  display: flex;
  align-items: center;
}
.dom__button--text {
  padding: 0 0.8rem;
}
.dom__button--left-icon {
  justify-content: flex-start;
}
.dom__button--right-icon {
  justify-content: flex-end;
}
.dom__button.--SECONDARY_BUTTON {
  background-color: #e0e7e9;
  color: #6a6a6a;
}
.dom__button.--TEXT_BUTTON {
  background-color: transparent;
  padding: 0.4rem;
  color: #a93e73;
}
.dom__button.--TEXT_BUTTON:hover {
  text-decoration: underline;
}
.dom__button.with-left-icon {
  grid-template-columns: 2rem auto;
}
.dom__button.with-left-icon .dom__button--text {
  padding-left: 0.8rem;
}
.dom__button.with-right-icon {
  grid-template-columns: auto 2rem;
}
.dom__button.with-right-icon .dom__button--text {
  padding-right: 0.8rem;
}
.dom__button.with-two-icons {
  grid-template-columns: 2rem auto 2rem;
}
.dom__button.with-two-icons .dom__button--text {
  padding: 0 0.8rem;
}
.dom__button:hover {
  -webkit-filter: brightness(0.9);
  -ms-filter: brightness(0.9);
  filter: brightness(0.9);
}
.dom__button:disabled {
  background-color: #bebebe !important;
  color: #171717;
  cursor: not-allowed;
  -webkit-filter: none;
  -ms-filter: none;
  filter: none;
}
.dom__form {
  display: grid;
  color: #171717;
  align-items: center;
  background-color: #ffffff;
  gap: 2.4rem 6.4rem;
  grid-template-columns: 2fr 2fr;
}
.dom__form--title {
  font-weight: 900;
  margin-bottom: 2.4rem;
}
.dom__form--element .wrapper .input-label {
  font-size: 1.6rem;
}
.dom__form--element .wrapper .text-input,
.dom__form--element .wrapper .select-input__search {
  height: 6rem;
  min-height: 6rem;
  font-size: 1.8rem;
  font-weight: 600;
}
.dom__form--element .wrapper .text-area {
  resize: none;
  font-weight: 600;
  font-size: 1.8rem;
  padding: 1.2rem;
  min-height: 25rem;
}
.dom__form--element .wrapper .datepicker-input {
  min-height: 6rem;
  font-weight: 600;
  font-size: 1.8rem;
}
.dom__form--element .wrapper .datepicker-input .clear {
  min-height: 6rem;
  min-width: 5rem;
}
.dom__form--element .dom__button {
  font-size: 1.8rem;
  padding: 0.8rem 3.2rem;
  margin: 0 auto;
}
.dom__form--element .select .select-input {
  display: flex;
  align-items: center;
}
.dom__form--element .select .select-input__search, .dom__form--element .select .select-input__controls {
  height: 6rem;
  min-height: 6rem;
}
.dom__form--image {
  display: flex;
  align-items: center;
  justify-content: center;
}
.dom__form--image .img {
  max-width: 100%;
  max-height: 100%;
  width: auto;
  height: auto;
}
.dom__contact-form--left {
  padding: 4rem 2.4rem 4rem 10rem;
}
.dom__gallery {
  padding: 2.4rem 6.4rem;
}
.dom__gallery--images {
  max-width: 85%;
  margin: 0 auto;
}
.dom__gallery--images .img-container {
  display: flex;
  align-items: center;
  justify-content: center;
}
.dom__gallery--show-more {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 1.6rem;
}
.dom__gallery .grid-system {
  display: grid;
  row-gap: 1.2rem;
  column-gap: 1.2rem;
}
.dom__gallery .grid-2 {
  grid-template-columns: repeat(2, 1fr);
}
.dom__gallery .grid-3 {
  grid-template-columns: repeat(3, 1fr);
}
.dom__gallery .grid-4 {
  max-width: 90%;
  column-gap: 4rem;
  grid-template-columns: repeat(4, 1fr);
}
.dom__gallery .grid-5 {
  max-width: 95%;
  row-gap: 3.2rem;
  column-gap: 3.2rem;
  grid-template-columns: repeat(5, 1fr);
}
.dom__image-viewer {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: grid;
  z-index: 10000;
  padding: 0 2.4rem;
  align-items: flex-start;
  background-color: rgba(0, 0, 0, 0.9);
  grid-template-columns: 4rem auto 4rem;
  grid-template-rows: 8rem auto;
  -webkit-box-shadow: 2px 2px 12px 4px rgba(255, 255, 255, 0.2);
  -moz-box-shadow: 2px 2px 12px 4px rgba(255, 255, 255, 0.2);
  box-shadow: 2px 2px 12px 4px rgba(255, 255, 255, 0.2);
}
.dom__image-viewer--heading {
  grid-area: 1/1/2/4;
  display: flex;
  justify-content: flex-end;
}
.dom__image-viewer--heading--close {
  margin-top: 4rem;
  background-color: #ffffff;
  width: 4rem;
  height: 4rem;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #171717;
  cursor: pointer;
  grid-area: 1/3/2/4;
}
.dom__image-viewer--left, .dom__image-viewer--right {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #ffffff;
  height: 90%;
}
.dom__image-viewer--left:hover, .dom__image-viewer--right:hover {
  background-color: #ffffff;
  color: #6a6a6a;
}
.dom__image-viewer--container {
  display: flex;
  overflow: auto;
  align-items: center;
  justify-content: center;
  height: 90%;
}
.dom__app-store {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}
.dom__app-store--icon {
  display: flex;
  justify-content: center;
  align-items: center;
}
.dom__app-store--icon:first-of-type {
  margin-right: 1.2rem;
}
.dom__app-store--icon .image {
  max-height: 6rem;
  width: auto;
}
.dom__app-store--icon .svg {
  width: auto;
}

.page-lg .dom__gallery--images {
  max-width: 90%;
}

.page-md .dom__gallery--images {
  max-width: 100%;
}
.page-md .dom__form--element .datepicker-input {
  min-height: unset;
  font-size: 1.4rem;
}
.page-md .dom__form--element .datepicker-input .clear {
  min-width: unset;
  min-height: unset;
}
.page-md .dom__form--element .input-label {
  font-size: 1.2rem;
}
.page-md .dom__form--element .text-input,
.page-md .dom__form--element .text-area {
  font-size: 1.4rem;
  min-height: unset;
}

.page-sm .dom__form {
  gap: 1.2rem;
}
.page-sm .dom__form--title {
  font-size: 2.4rem;
  margin-bottom: 0.8rem;
}
.page-sm .dom__form--element .text-input,
.page-sm .dom__form--element .text-area {
  font-size: 1.4rem;
  font-weight: 400;
  min-height: unset;
}
.page-sm .dom__form--element .datepicker-input {
  min-height: unset;
  font-size: 1.4rem;
  font-weight: 400;
}
.page-sm .dom__form--element .datepicker-input .clear {
  min-width: unset;
  min-height: unset;
}
.page-sm .dom__form--element .input-label {
  font-size: 1.2rem;
}
.page-sm .dom__form--element .text-area {
  min-height: 8rem;
}
.page-sm .dom__form--element .dom__button {
  padding: 0.8rem 1.6rem;
  font-size: 1.6rem;
}
.page-sm .dom__contact-form--left {
  padding-left: 1.2rem;
  padding-right: 0;
}
.page-sm .dom__gallery--images {
  max-width: 100%;
}

.page-xs .dom__contact-form--right {
  grid-area: 1/1/2/2;
}
.page-xs .dom__contact-form--left {
  padding: 1.6rem 0;
  grid-area: 2/1/3/2;
}
.page-xs .dom__form {
  grid-template-columns: 1fr;
  padding: 2.4rem 0 0;
  gap: 1.2rem;
}
.page-xs .dom__form--title {
  font-size: 2.4rem;
  margin-bottom: 0.8rem;
  text-align: center;
}
.page-xs .dom__form--element {
  padding: 0 0.8rem;
}
.page-xs .dom__form--element .text-input,
.page-xs .dom__form--element .text-area {
  font-size: 1.4rem;
  font-weight: 400;
  min-height: unset;
}
.page-xs .dom__form--element .datepicker-input {
  min-height: unset;
  font-size: 1.4rem;
  font-weight: 400;
}
.page-xs .dom__form--element .datepicker-input .clear {
  min-height: 4rem;
  min-width: 4rem;
}
.page-xs .dom__form--element .input-label {
  font-size: 1.2rem;
}
.page-xs .dom__form--element .text-area {
  min-height: 8rem;
}
.page-xs .dom__form--element .dom__button {
  padding: 0.8rem 1.6rem;
  font-size: 1.6rem;
}
.page-xs .dom__gallery {
  padding: 1.2rem 1.6rem;
}
.page-xs .dom__gallery--images {
  column-gap: 0.4rem;
  max-width: 100%;
}
.page-xs .dom__gallery .grid-4 {
  grid-template-columns: repeat(2, 1fr);
}
.page-xs .dom__gallery .grid-5,
.page-xs .dom__gallery .grid-3 {
  grid-template-columns: 1fr;
}
.page-xs .dom__image-viewer {
  padding: 0 0.4rem;
}
.page-xs .dom__app-store {
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.page-xs .dom__app-store--icon:first-of-type {
  margin-right: 0;
}

.dark-theme {
  background-color: #171717;
  color: #e7ecef;
}
.dark-theme .checkbox-container .check-mark {
  border-color: #ffffff;
}
.dark-theme .section {
  color: #e7ecef;
}
.dark-theme .column {
  color: #e7ecef;
}
.dark-theme .dom__header .h {
  color: #e7ecef;
}
.dark-theme .dom__text {
  color: #e7ecef;
}
.dark-theme .dom__link:hover {
  -webkit-filter: brightness(115%);
  -ms-filter: brightness(115%);
  filter: brightness(115%);
}
.dark-theme .dom__link--button-type {
  background-color: #333333;
  color: #e7ecef;
}
.dark-theme .dom__bullet-list {
  color: #e7ecef;
}
.dark-theme .dom__form {
  background-color: #171717;
  color: #e7ecef;
}
.dark-theme .dom__form .input-label {
  color: #e7ecef;
}
.dark-theme .dom__form .text-input,
.dark-theme .dom__form .text-area {
  background-color: #585858;
  color: #e7ecef;
}

.selector {
  min-height: calc(100vh - 5rem);
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-position: center;
  justify-content: center;
  background-size: cover;
  flex-direction: column;
  align-items: center;
  position: relative;
  display: flex;
  width: 100%;
}
.selector__input-container {
  width: 100%;
  max-width: 34.8rem;
  position: relative;
  padding: 2.4rem;
  background-color: #ffffff;
}
.selector__input-container .wrapper {
  margin-bottom: 0;
}
.selector__input-container .raw-select-input {
  max-width: 30rem;
  width: 100%;
}
.selector__select-submit {
  display: flex;
  font-size: 1.6rem;
  justify-content: center;
  padding-top: 0.8rem;
}
.selector__select-submit .atag-link {
  width: 100%;
  display: flex;
  align-items: center;
  margin-top: 0.8rem;
  -webkit-border-radius: 1rem;
  -moz-border-radius: 1rem;
  border-radius: 1rem;
  justify-content: space-between;
  padding: 0.8rem 1.2rem;
}
.selector__image-container {
  justify-content: center;
  align-items: center;
  position: absolute;
  max-width: 100%;
  display: flex;
  right: 0;
  left: 0;
}
.selector__image-container--top-logo {
  max-width: 14rem;
  height: auto;
  max-height: 14rem;
  width: auto;
}
.selector__title {
  text-align: center;
  padding-bottom: 1.6rem;
}
.selector__description {
  font-size: 1.6rem;
  text-align: center;
  margin-top: -1.2rem;
  padding-bottom: 2.4rem;
}
.selector__tile {
  width: 100%;
  height: 100%;
  display: flex;
  color: #e7ecef;
  max-width: 25rem;
  min-width: 25rem;
  min-height: 26rem;
  text-align: center;
  align-self: center;
  align-items: center;
  justify-self: center;
  padding: 2.4rem;
  flex-direction: column;
  justify-content: center;
  -webkit-transition: box-shadow 200ms linear;
  -moz-transition: box-shadow 200ms linear;
  -ms-transition: box-shadow 200ms linear;
  -o-transition: box-shadow 200ms linear;
  transition: box-shadow 200ms linear;
  -webkit-box-shadow: 0 0 3rem 0rem #00000066;
  -moz-box-shadow: 0 0 3rem 0rem #00000066;
  box-shadow: 0 0 3rem 0rem #00000066;
  background-image: -moz-linear-gradient(0deg, #171717 0%, #171717 30%, #00000088 100%);
  background-image: -webkit-gradient(0deg, #171717 0%, #171717 30%, #00000088 100%);
  background-image: -webkit-linear-gradient(0deg, #171717 0%, #171717 30%, #00000088 100%);
  background-image: -o-linear-gradient(0deg, #171717 0%, #171717 30%, #00000088 100%);
  background-image: -ms-linear-gradient(0deg, #171717 0%, #171717 30%, #00000088 100%);
  background-image: linear-gradient(0deg, #171717 0%, #171717 30%, #00000088 100%);
}
.selector__tile:hover {
  -webkit-box-shadow: 0 0 0.2rem 0rem #000000cc;
  -moz-box-shadow: 0 0 0.2rem 0rem #000000cc;
  box-shadow: 0 0 0.2rem 0rem #000000cc;
}
.selector__tile--location {
  font-size: 1.8rem;
  padding: 1.2rem;
}
.selector__tile--name {
  font-weight: 600;
  font-size: 2.4rem;
}
.selector__tile--phone {
  padding-top: 1.2rem;
}
.selector__tile.--align-right {
  justify-self: flex-end;
}
.selector__tile.--align-left {
  justify-self: flex-start;
}
.selector__address-form {
  width: 100%;
  flex-grow: 1;
  display: grid;
  max-width: 60rem;
  padding: 2.4rem;
  background-color: #ffffff;
  gap: 0 1.2rem;
  grid-template-columns: repeat(4, 1fr);
  -webkit-box-shadow: 0 0 1rem 0rem #00000066;
  -moz-box-shadow: 0 0 1rem 0rem #00000066;
  box-shadow: 0 0 1rem 0rem #00000066;
}
.selector__address-form .submit-button {
  height: 4rem;
  align-self: flex-end;
  margin-bottom: 1.7rem;
}
.selector__address-form .wrapper {
  min-height: 8rem;
  margin-bottom: 0;
  padding-bottom: 0;
  align-self: flex-end;
}
.selector__address-form--title {
  grid-column: 1/5;
  text-align: center;
  margin-bottom: 0.4rem;
  border-bottom: 1px solid #e0e7e9;
}
.selector__address-form .delivery_region_id,
.selector__address-form .restaurant_id,
.selector__address-form .city {
  grid-column: 1/3;
}
.selector__address-form .submit-button,
.selector__address-form .street_id,
.selector__address-form .street {
  grid-column: 3/5;
}
.selector__address-form.--WITH_ZIPCODE .zipcode {
  grid-column: 1/2 !important;
}
.selector__address-form.--WITH_ZIPCODE .city {
  grid-column: 2/3 !important;
}
.selector.selector-tile_layout {
  display: grid;
  row-gap: 1.2rem;
  align-items: stretch;
  column-gap: 1.2rem;
  justify-content: stretch;
}
.selector.selector-tile_layout.--1col {
  grid-template-columns: 1fr;
}
.selector.selector-tile_layout.--2col {
  grid-template-columns: repeat(2, 1fr);
  column-gap: 10%;
}
.selector.selector-tile_layout.--3col {
  grid-template-columns: repeat(3, 1fr);
}
.selector.selector-tile_layout.--3col .selector__tile {
  justify-self: center;
}
.selector.selector-tile_layout.--TOP {
  align-items: flex-start;
}
.selector.selector-tile_layout.--TOP .selector__tile {
  max-width: 30rem;
}
.selector.selector-tile_layout.--BOTTOM {
  align-items: flex-end;
}
.selector.selector-tile_layout.--BOTTOM .selector__tile {
  max-width: 30rem;
}
.selector.selector-address_based {
  width: 100%;
  z-index: 101;
  min-height: auto;
  padding: 2.4rem;
  align-items: flex-start;
  background-size: contain;
  background-position: right;
  background-repeat: no-repeat;
  background-attachment: unset;
  background-image: url("Assets/addresschooser_bg.avif");
}
.selector.selector-address_based .address-container {
  width: 100%;
  height: 100%;
  background-size: contain;
  background-position: right;
  background-repeat: no-repeat;
  background-attachment: unset;
}

.rounded-corners .text-input,
.rounded-corners .select-input,
.rounded-corners .select-input__search {
  -webkit-border-radius: 1rem;
  -moz-border-radius: 1rem;
  border-radius: 1rem;
}
.rounded-corners .select-input__list {
  -webkit-border-radius: 1rem;
  -moz-border-radius: 1rem;
  border-radius: 1rem;
  padding: 0.8rem 0;
}
.rounded-corners .selector__address-form {
  -webkit-border-radius: 1rem;
  -moz-border-radius: 1rem;
  border-radius: 1rem;
}
.rounded-corners .selector__input-container {
  -webkit-border-radius: 1rem;
  -moz-border-radius: 1rem;
  border-radius: 1rem;
}

.selector-lg,
.selector-md,
.selector-sm,
.selector-xs {
  min-height: calc(100vh - 2rem);
}

.page-sm .selector-address_based,
.page-xs .selector-address_based {
  justify-content: center;
  align-items: center;
}

.selector-sm.selector-tile_layout.--2col,
.selector-xs.selector-tile_layout.--2col {
  column-gap: 0;
  grid-template-columns: 1fr;
}
.selector-sm.selector-tile_layout.--2col .selector__tile-container:nth-of-type(1n),
.selector-xs.selector-tile_layout.--2col .selector__tile-container:nth-of-type(1n) {
  justify-content: center;
}

.selector-xs {
  padding: 0 1.2rem;
}
.selector-xs .selector__address-form {
  width: 100%;
  row-gap: 1.2rem;
  grid-template-columns: repeat(2, 1fr);
}
.selector-xs .selector__address-form--title {
  grid-column: 1/3;
}
.selector-xs .selector__address-form .delivery_region_id,
.selector-xs .selector__address-form .city,
.selector-xs .selector__address-form .restaurant_id,
.selector-xs .selector__address-form .street_id,
.selector-xs .selector__address-form .street,
.selector-xs .selector__address-form .submit-button {
  grid-column: 1/3;
}
.selector-xs .selector__title {
  font-size: 2.4rem;
}

.dark-theme .selector__address-form {
  background-color: #393939;
}
.dark-theme .selector__address-form .input-label {
  color: #e7ecef;
}
.dark-theme .selector__input-container {
  background-color: #333333;
}
.dark-theme .text-input:disabled,
.dark-theme .common-input:disabled,
.dark-theme input:disabled {
  background-color: #999999;
}

.--CENTERED_STYLE_WITH_SIDE_IMAGE .address-container {
  max-width: 120rem !important;
  margin: 0 auto !important;
  width: 100%;
}
@media screen and (max-width: 1200px) {
  .--CENTERED_STYLE_WITH_SIDE_IMAGE .address-container {
    display: flex !important;
    background-image: none !important;
    justify-content: center !important;
    align-items: center !important;
  }
}

.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
}

@-webkit-keyframes smrotate {
  0% {
    transform: rotate(0deg) scale(0.8);
    -webkit-transform: rotate(0deg) scale(0.8);
    -moz-transform: rotate(0deg) scale(0.8);
    -ms-transform: rotate(0deg) scale(0.8);
    -o-transform: rotate(0deg) scale(0.8);
  }
  50% {
    transform: rotate(360deg) scale(1.2);
    -webkit-transform: rotate(360deg) scale(1.2);
    -moz-transform: rotate(360deg) scale(1.2);
    -ms-transform: rotate(360deg) scale(1.2);
    -o-transform: rotate(360deg) scale(1.2);
  }
  100% {
    transform: rotate(720deg) scale(0.8);
    -webkit-transform: rotate(720deg) scale(0.8);
    -moz-transform: rotate(720deg) scale(0.8);
    -ms-transform: rotate(720deg) scale(0.8);
    -o-transform: rotate(720deg) scale(0.8);
  }
}
@-moz-keyframes smrotate {
  0% {
    transform: rotate(0deg) scale(0.8);
    -webkit-transform: rotate(0deg) scale(0.8);
    -moz-transform: rotate(0deg) scale(0.8);
    -ms-transform: rotate(0deg) scale(0.8);
    -o-transform: rotate(0deg) scale(0.8);
  }
  50% {
    transform: rotate(360deg) scale(1.2);
    -webkit-transform: rotate(360deg) scale(1.2);
    -moz-transform: rotate(360deg) scale(1.2);
    -ms-transform: rotate(360deg) scale(1.2);
    -o-transform: rotate(360deg) scale(1.2);
  }
  100% {
    transform: rotate(720deg) scale(0.8);
    -webkit-transform: rotate(720deg) scale(0.8);
    -moz-transform: rotate(720deg) scale(0.8);
    -ms-transform: rotate(720deg) scale(0.8);
    -o-transform: rotate(720deg) scale(0.8);
  }
}
@-ms-keyframes smrotate {
  0% {
    transform: rotate(0deg) scale(0.8);
    -webkit-transform: rotate(0deg) scale(0.8);
    -moz-transform: rotate(0deg) scale(0.8);
    -ms-transform: rotate(0deg) scale(0.8);
    -o-transform: rotate(0deg) scale(0.8);
  }
  50% {
    transform: rotate(360deg) scale(1.2);
    -webkit-transform: rotate(360deg) scale(1.2);
    -moz-transform: rotate(360deg) scale(1.2);
    -ms-transform: rotate(360deg) scale(1.2);
    -o-transform: rotate(360deg) scale(1.2);
  }
  100% {
    transform: rotate(720deg) scale(0.8);
    -webkit-transform: rotate(720deg) scale(0.8);
    -moz-transform: rotate(720deg) scale(0.8);
    -ms-transform: rotate(720deg) scale(0.8);
    -o-transform: rotate(720deg) scale(0.8);
  }
}
@keyframes smrotate {
  0% {
    transform: rotate(0deg) scale(0.8);
    -webkit-transform: rotate(0deg) scale(0.8);
    -moz-transform: rotate(0deg) scale(0.8);
    -ms-transform: rotate(0deg) scale(0.8);
    -o-transform: rotate(0deg) scale(0.8);
  }
  50% {
    transform: rotate(360deg) scale(1.2);
    -webkit-transform: rotate(360deg) scale(1.2);
    -moz-transform: rotate(360deg) scale(1.2);
    -ms-transform: rotate(360deg) scale(1.2);
    -o-transform: rotate(360deg) scale(1.2);
  }
  100% {
    transform: rotate(720deg) scale(0.8);
    -webkit-transform: rotate(720deg) scale(0.8);
    -moz-transform: rotate(720deg) scale(0.8);
    -ms-transform: rotate(720deg) scale(0.8);
    -o-transform: rotate(720deg) scale(0.8);
  }
}
@-webkit-keyframes smball1 {
  0% {
    -webkit-box-shadow: 15px 0 0 #ff781d;
    -moz-box-shadow: 15px 0 0 #ff781d;
    box-shadow: 15px 0 0 #ff781d;
  }
  50% {
    -webkit-box-shadow: 0 0 0 #ff781d;
    -moz-box-shadow: 0 0 0 #ff781d;
    box-shadow: 0 0 0 #ff781d;
    margin-bottom: 0;
    transform: translate(7.5px, 7.5px);
    -webkit-transform: translate(7.5px, 7.5px);
    -moz-transform: translate(7.5px, 7.5px);
    -ms-transform: translate(7.5px, 7.5px);
    -o-transform: translate(7.5px, 7.5px);
  }
  100% {
    -webkit-box-shadow: 15px 0 0 #ff781d;
    -moz-box-shadow: 15px 0 0 #ff781d;
    box-shadow: 15px 0 0 #ff781d;
    margin-bottom: 5px;
  }
}
@-moz-keyframes smball1 {
  0% {
    -webkit-box-shadow: 15px 0 0 #ff781d;
    -moz-box-shadow: 15px 0 0 #ff781d;
    box-shadow: 15px 0 0 #ff781d;
  }
  50% {
    -webkit-box-shadow: 0 0 0 #ff781d;
    -moz-box-shadow: 0 0 0 #ff781d;
    box-shadow: 0 0 0 #ff781d;
    margin-bottom: 0;
    transform: translate(7.5px, 7.5px);
    -webkit-transform: translate(7.5px, 7.5px);
    -moz-transform: translate(7.5px, 7.5px);
    -ms-transform: translate(7.5px, 7.5px);
    -o-transform: translate(7.5px, 7.5px);
  }
  100% {
    -webkit-box-shadow: 15px 0 0 #ff781d;
    -moz-box-shadow: 15px 0 0 #ff781d;
    box-shadow: 15px 0 0 #ff781d;
    margin-bottom: 5px;
  }
}
@-ms-keyframes smball1 {
  0% {
    -webkit-box-shadow: 15px 0 0 #ff781d;
    -moz-box-shadow: 15px 0 0 #ff781d;
    box-shadow: 15px 0 0 #ff781d;
  }
  50% {
    -webkit-box-shadow: 0 0 0 #ff781d;
    -moz-box-shadow: 0 0 0 #ff781d;
    box-shadow: 0 0 0 #ff781d;
    margin-bottom: 0;
    transform: translate(7.5px, 7.5px);
    -webkit-transform: translate(7.5px, 7.5px);
    -moz-transform: translate(7.5px, 7.5px);
    -ms-transform: translate(7.5px, 7.5px);
    -o-transform: translate(7.5px, 7.5px);
  }
  100% {
    -webkit-box-shadow: 15px 0 0 #ff781d;
    -moz-box-shadow: 15px 0 0 #ff781d;
    box-shadow: 15px 0 0 #ff781d;
    margin-bottom: 5px;
  }
}
@keyframes smball1 {
  0% {
    -webkit-box-shadow: 15px 0 0 #ff781d;
    -moz-box-shadow: 15px 0 0 #ff781d;
    box-shadow: 15px 0 0 #ff781d;
  }
  50% {
    -webkit-box-shadow: 0 0 0 #ff781d;
    -moz-box-shadow: 0 0 0 #ff781d;
    box-shadow: 0 0 0 #ff781d;
    margin-bottom: 0;
    transform: translate(7.5px, 7.5px);
    -webkit-transform: translate(7.5px, 7.5px);
    -moz-transform: translate(7.5px, 7.5px);
    -ms-transform: translate(7.5px, 7.5px);
    -o-transform: translate(7.5px, 7.5px);
  }
  100% {
    -webkit-box-shadow: 15px 0 0 #ff781d;
    -moz-box-shadow: 15px 0 0 #ff781d;
    box-shadow: 15px 0 0 #ff781d;
    margin-bottom: 5px;
  }
}
@-webkit-keyframes smball2 {
  0% {
    -webkit-box-shadow: 15px 0 0 #fb3350;
    -moz-box-shadow: 15px 0 0 #fb3350;
    box-shadow: 15px 0 0 #fb3350;
  }
  50% {
    -webkit-box-shadow: 0 0 0 #fb3350;
    -moz-box-shadow: 0 0 0 #fb3350;
    box-shadow: 0 0 0 #fb3350;
    margin-top: -10px;
    transform: translate(7.5px, 7.5px);
    -webkit-transform: translate(7.5px, 7.5px);
    -moz-transform: translate(7.5px, 7.5px);
    -ms-transform: translate(7.5px, 7.5px);
    -o-transform: translate(7.5px, 7.5px);
  }
  100% {
    -webkit-box-shadow: 15px 0 0 #fb3350;
    -moz-box-shadow: 15px 0 0 #fb3350;
    box-shadow: 15px 0 0 #fb3350;
    margin-top: 0;
  }
}
@-moz-keyframes smball2 {
  0% {
    -webkit-box-shadow: 15px 0 0 #fb3350;
    -moz-box-shadow: 15px 0 0 #fb3350;
    box-shadow: 15px 0 0 #fb3350;
  }
  50% {
    -webkit-box-shadow: 0 0 0 #fb3350;
    -moz-box-shadow: 0 0 0 #fb3350;
    box-shadow: 0 0 0 #fb3350;
    margin-top: -10px;
    transform: translate(7.5px, 7.5px);
    -webkit-transform: translate(7.5px, 7.5px);
    -moz-transform: translate(7.5px, 7.5px);
    -ms-transform: translate(7.5px, 7.5px);
    -o-transform: translate(7.5px, 7.5px);
  }
  100% {
    -webkit-box-shadow: 15px 0 0 #fb3350;
    -moz-box-shadow: 15px 0 0 #fb3350;
    box-shadow: 15px 0 0 #fb3350;
    margin-top: 0;
  }
}
@-ms-keyframes smball2 {
  0% {
    -webkit-box-shadow: 15px 0 0 #fb3350;
    -moz-box-shadow: 15px 0 0 #fb3350;
    box-shadow: 15px 0 0 #fb3350;
  }
  50% {
    -webkit-box-shadow: 0 0 0 #fb3350;
    -moz-box-shadow: 0 0 0 #fb3350;
    box-shadow: 0 0 0 #fb3350;
    margin-top: -10px;
    transform: translate(7.5px, 7.5px);
    -webkit-transform: translate(7.5px, 7.5px);
    -moz-transform: translate(7.5px, 7.5px);
    -ms-transform: translate(7.5px, 7.5px);
    -o-transform: translate(7.5px, 7.5px);
  }
  100% {
    -webkit-box-shadow: 15px 0 0 #fb3350;
    -moz-box-shadow: 15px 0 0 #fb3350;
    box-shadow: 15px 0 0 #fb3350;
    margin-top: 0;
  }
}
@keyframes smball2 {
  0% {
    -webkit-box-shadow: 15px 0 0 #fb3350;
    -moz-box-shadow: 15px 0 0 #fb3350;
    box-shadow: 15px 0 0 #fb3350;
  }
  50% {
    -webkit-box-shadow: 0 0 0 #fb3350;
    -moz-box-shadow: 0 0 0 #fb3350;
    box-shadow: 0 0 0 #fb3350;
    margin-top: -10px;
    transform: translate(7.5px, 7.5px);
    -webkit-transform: translate(7.5px, 7.5px);
    -moz-transform: translate(7.5px, 7.5px);
    -ms-transform: translate(7.5px, 7.5px);
    -o-transform: translate(7.5px, 7.5px);
  }
  100% {
    -webkit-box-shadow: 15px 0 0 #fb3350;
    -moz-box-shadow: 15px 0 0 #fb3350;
    box-shadow: 15px 0 0 #fb3350;
    margin-top: 0;
  }
}
.loading-size-sm .loader {
  -webkit-animation: smrotate 1s infinite;
  -moz-animation: smrotate 1s infinite;
  -ms-animation: smrotate 1s infinite;
  -o-animation: smrotate 1s infinite;
  animation: smrotate 1s infinite;
  height: 25px;
  width: 25px;
  margin-bottom: 1.2rem;
}
.loading-size-sm .loader:before,
.loading-size-sm .loader:after {
  border-radius: 50%;
  content: "";
  display: block;
  height: 10px;
  width: 10px;
}
.loading-size-sm .loader:before {
  -webkit-box-shadow: 15px 0 0 #ff781d;
  -moz-box-shadow: 15px 0 0 #ff781d;
  box-shadow: 15px 0 0 #ff781d;
  margin-bottom: 5px;
  -webkit-animation: smball1 1s infinite;
  -moz-animation: smball1 1s infinite;
  -ms-animation: smball1 1s infinite;
  -o-animation: smball1 1s infinite;
  animation: smball1 1s infinite;
  background-color: #fb3350;
}
.loading-size-sm .loader:after {
  -webkit-animation: smball2 1s infinite;
  -moz-animation: smball2 1s infinite;
  -ms-animation: smball2 1s infinite;
  -o-animation: smball2 1s infinite;
  animation: smball2 1s infinite;
  background-color: #ff781d;
  -webkit-box-shadow: 15px 0 0 #fb3350;
  -moz-box-shadow: 15px 0 0 #fb3350;
  box-shadow: 15px 0 0 #fb3350;
}

@-webkit-keyframes mdrotate {
  0% {
    transform: rotate(0deg) scale(0.8);
    -webkit-transform: rotate(0deg) scale(0.8);
    -moz-transform: rotate(0deg) scale(0.8);
    -ms-transform: rotate(0deg) scale(0.8);
    -o-transform: rotate(0deg) scale(0.8);
  }
  50% {
    transform: rotate(360deg) scale(1.2);
    -webkit-transform: rotate(360deg) scale(1.2);
    -moz-transform: rotate(360deg) scale(1.2);
    -ms-transform: rotate(360deg) scale(1.2);
    -o-transform: rotate(360deg) scale(1.2);
  }
  100% {
    transform: rotate(720deg) scale(0.8);
    -webkit-transform: rotate(720deg) scale(0.8);
    -moz-transform: rotate(720deg) scale(0.8);
    -ms-transform: rotate(720deg) scale(0.8);
    -o-transform: rotate(720deg) scale(0.8);
  }
}
@-moz-keyframes mdrotate {
  0% {
    transform: rotate(0deg) scale(0.8);
    -webkit-transform: rotate(0deg) scale(0.8);
    -moz-transform: rotate(0deg) scale(0.8);
    -ms-transform: rotate(0deg) scale(0.8);
    -o-transform: rotate(0deg) scale(0.8);
  }
  50% {
    transform: rotate(360deg) scale(1.2);
    -webkit-transform: rotate(360deg) scale(1.2);
    -moz-transform: rotate(360deg) scale(1.2);
    -ms-transform: rotate(360deg) scale(1.2);
    -o-transform: rotate(360deg) scale(1.2);
  }
  100% {
    transform: rotate(720deg) scale(0.8);
    -webkit-transform: rotate(720deg) scale(0.8);
    -moz-transform: rotate(720deg) scale(0.8);
    -ms-transform: rotate(720deg) scale(0.8);
    -o-transform: rotate(720deg) scale(0.8);
  }
}
@-ms-keyframes mdrotate {
  0% {
    transform: rotate(0deg) scale(0.8);
    -webkit-transform: rotate(0deg) scale(0.8);
    -moz-transform: rotate(0deg) scale(0.8);
    -ms-transform: rotate(0deg) scale(0.8);
    -o-transform: rotate(0deg) scale(0.8);
  }
  50% {
    transform: rotate(360deg) scale(1.2);
    -webkit-transform: rotate(360deg) scale(1.2);
    -moz-transform: rotate(360deg) scale(1.2);
    -ms-transform: rotate(360deg) scale(1.2);
    -o-transform: rotate(360deg) scale(1.2);
  }
  100% {
    transform: rotate(720deg) scale(0.8);
    -webkit-transform: rotate(720deg) scale(0.8);
    -moz-transform: rotate(720deg) scale(0.8);
    -ms-transform: rotate(720deg) scale(0.8);
    -o-transform: rotate(720deg) scale(0.8);
  }
}
@keyframes mdrotate {
  0% {
    transform: rotate(0deg) scale(0.8);
    -webkit-transform: rotate(0deg) scale(0.8);
    -moz-transform: rotate(0deg) scale(0.8);
    -ms-transform: rotate(0deg) scale(0.8);
    -o-transform: rotate(0deg) scale(0.8);
  }
  50% {
    transform: rotate(360deg) scale(1.2);
    -webkit-transform: rotate(360deg) scale(1.2);
    -moz-transform: rotate(360deg) scale(1.2);
    -ms-transform: rotate(360deg) scale(1.2);
    -o-transform: rotate(360deg) scale(1.2);
  }
  100% {
    transform: rotate(720deg) scale(0.8);
    -webkit-transform: rotate(720deg) scale(0.8);
    -moz-transform: rotate(720deg) scale(0.8);
    -ms-transform: rotate(720deg) scale(0.8);
    -o-transform: rotate(720deg) scale(0.8);
  }
}
@-webkit-keyframes mdball1 {
  0% {
    -webkit-box-shadow: 30px 0 0 #ff781d;
    -moz-box-shadow: 30px 0 0 #ff781d;
    box-shadow: 30px 0 0 #ff781d;
  }
  50% {
    -webkit-box-shadow: 0 0 0 #ff781d;
    -moz-box-shadow: 0 0 0 #ff781d;
    box-shadow: 0 0 0 #ff781d;
    margin-bottom: 0;
    transform: translate(15px, 15px);
    -webkit-transform: translate(15px, 15px);
    -moz-transform: translate(15px, 15px);
    -ms-transform: translate(15px, 15px);
    -o-transform: translate(15px, 15px);
  }
  100% {
    -webkit-box-shadow: 30px 0 0 #ff781d;
    -moz-box-shadow: 30px 0 0 #ff781d;
    box-shadow: 30px 0 0 #ff781d;
    margin-bottom: 10px;
  }
}
@-moz-keyframes mdball1 {
  0% {
    -webkit-box-shadow: 30px 0 0 #ff781d;
    -moz-box-shadow: 30px 0 0 #ff781d;
    box-shadow: 30px 0 0 #ff781d;
  }
  50% {
    -webkit-box-shadow: 0 0 0 #ff781d;
    -moz-box-shadow: 0 0 0 #ff781d;
    box-shadow: 0 0 0 #ff781d;
    margin-bottom: 0;
    transform: translate(15px, 15px);
    -webkit-transform: translate(15px, 15px);
    -moz-transform: translate(15px, 15px);
    -ms-transform: translate(15px, 15px);
    -o-transform: translate(15px, 15px);
  }
  100% {
    -webkit-box-shadow: 30px 0 0 #ff781d;
    -moz-box-shadow: 30px 0 0 #ff781d;
    box-shadow: 30px 0 0 #ff781d;
    margin-bottom: 10px;
  }
}
@-ms-keyframes mdball1 {
  0% {
    -webkit-box-shadow: 30px 0 0 #ff781d;
    -moz-box-shadow: 30px 0 0 #ff781d;
    box-shadow: 30px 0 0 #ff781d;
  }
  50% {
    -webkit-box-shadow: 0 0 0 #ff781d;
    -moz-box-shadow: 0 0 0 #ff781d;
    box-shadow: 0 0 0 #ff781d;
    margin-bottom: 0;
    transform: translate(15px, 15px);
    -webkit-transform: translate(15px, 15px);
    -moz-transform: translate(15px, 15px);
    -ms-transform: translate(15px, 15px);
    -o-transform: translate(15px, 15px);
  }
  100% {
    -webkit-box-shadow: 30px 0 0 #ff781d;
    -moz-box-shadow: 30px 0 0 #ff781d;
    box-shadow: 30px 0 0 #ff781d;
    margin-bottom: 10px;
  }
}
@keyframes mdball1 {
  0% {
    -webkit-box-shadow: 30px 0 0 #ff781d;
    -moz-box-shadow: 30px 0 0 #ff781d;
    box-shadow: 30px 0 0 #ff781d;
  }
  50% {
    -webkit-box-shadow: 0 0 0 #ff781d;
    -moz-box-shadow: 0 0 0 #ff781d;
    box-shadow: 0 0 0 #ff781d;
    margin-bottom: 0;
    transform: translate(15px, 15px);
    -webkit-transform: translate(15px, 15px);
    -moz-transform: translate(15px, 15px);
    -ms-transform: translate(15px, 15px);
    -o-transform: translate(15px, 15px);
  }
  100% {
    -webkit-box-shadow: 30px 0 0 #ff781d;
    -moz-box-shadow: 30px 0 0 #ff781d;
    box-shadow: 30px 0 0 #ff781d;
    margin-bottom: 10px;
  }
}
@-webkit-keyframes mdball2 {
  0% {
    -webkit-box-shadow: 30px 0 0 #fb3350;
    -moz-box-shadow: 30px 0 0 #fb3350;
    box-shadow: 30px 0 0 #fb3350;
  }
  50% {
    -webkit-box-shadow: 0 0 0 #fb3350;
    -moz-box-shadow: 0 0 0 #fb3350;
    box-shadow: 0 0 0 #fb3350;
    margin-top: -20px;
    transform: translate(15px, 15px);
    -webkit-transform: translate(15px, 15px);
    -moz-transform: translate(15px, 15px);
    -ms-transform: translate(15px, 15px);
    -o-transform: translate(15px, 15px);
  }
  100% {
    -webkit-box-shadow: 30px 0 0 #fb3350;
    -moz-box-shadow: 30px 0 0 #fb3350;
    box-shadow: 30px 0 0 #fb3350;
    margin-top: 0;
  }
}
@-moz-keyframes mdball2 {
  0% {
    -webkit-box-shadow: 30px 0 0 #fb3350;
    -moz-box-shadow: 30px 0 0 #fb3350;
    box-shadow: 30px 0 0 #fb3350;
  }
  50% {
    -webkit-box-shadow: 0 0 0 #fb3350;
    -moz-box-shadow: 0 0 0 #fb3350;
    box-shadow: 0 0 0 #fb3350;
    margin-top: -20px;
    transform: translate(15px, 15px);
    -webkit-transform: translate(15px, 15px);
    -moz-transform: translate(15px, 15px);
    -ms-transform: translate(15px, 15px);
    -o-transform: translate(15px, 15px);
  }
  100% {
    -webkit-box-shadow: 30px 0 0 #fb3350;
    -moz-box-shadow: 30px 0 0 #fb3350;
    box-shadow: 30px 0 0 #fb3350;
    margin-top: 0;
  }
}
@-ms-keyframes mdball2 {
  0% {
    -webkit-box-shadow: 30px 0 0 #fb3350;
    -moz-box-shadow: 30px 0 0 #fb3350;
    box-shadow: 30px 0 0 #fb3350;
  }
  50% {
    -webkit-box-shadow: 0 0 0 #fb3350;
    -moz-box-shadow: 0 0 0 #fb3350;
    box-shadow: 0 0 0 #fb3350;
    margin-top: -20px;
    transform: translate(15px, 15px);
    -webkit-transform: translate(15px, 15px);
    -moz-transform: translate(15px, 15px);
    -ms-transform: translate(15px, 15px);
    -o-transform: translate(15px, 15px);
  }
  100% {
    -webkit-box-shadow: 30px 0 0 #fb3350;
    -moz-box-shadow: 30px 0 0 #fb3350;
    box-shadow: 30px 0 0 #fb3350;
    margin-top: 0;
  }
}
@keyframes mdball2 {
  0% {
    -webkit-box-shadow: 30px 0 0 #fb3350;
    -moz-box-shadow: 30px 0 0 #fb3350;
    box-shadow: 30px 0 0 #fb3350;
  }
  50% {
    -webkit-box-shadow: 0 0 0 #fb3350;
    -moz-box-shadow: 0 0 0 #fb3350;
    box-shadow: 0 0 0 #fb3350;
    margin-top: -20px;
    transform: translate(15px, 15px);
    -webkit-transform: translate(15px, 15px);
    -moz-transform: translate(15px, 15px);
    -ms-transform: translate(15px, 15px);
    -o-transform: translate(15px, 15px);
  }
  100% {
    -webkit-box-shadow: 30px 0 0 #fb3350;
    -moz-box-shadow: 30px 0 0 #fb3350;
    box-shadow: 30px 0 0 #fb3350;
    margin-top: 0;
  }
}
.loading-size-md .loader {
  -webkit-animation: mdrotate 1s infinite;
  -moz-animation: mdrotate 1s infinite;
  -ms-animation: mdrotate 1s infinite;
  -o-animation: mdrotate 1s infinite;
  animation: mdrotate 1s infinite;
  height: 50px;
  width: 50px;
  margin-bottom: 1.2rem;
}
.loading-size-md .loader:before,
.loading-size-md .loader:after {
  border-radius: 50%;
  content: "";
  display: block;
  height: 20px;
  width: 20px;
}
.loading-size-md .loader:before {
  -webkit-box-shadow: 30px 0 0 #ff781d;
  -moz-box-shadow: 30px 0 0 #ff781d;
  box-shadow: 30px 0 0 #ff781d;
  margin-bottom: 10px;
  -webkit-animation: mdball1 1s infinite;
  -moz-animation: mdball1 1s infinite;
  -ms-animation: mdball1 1s infinite;
  -o-animation: mdball1 1s infinite;
  animation: mdball1 1s infinite;
  background-color: #fb3350;
}
.loading-size-md .loader:after {
  -webkit-animation: mdball2 1s infinite;
  -moz-animation: mdball2 1s infinite;
  -ms-animation: mdball2 1s infinite;
  -o-animation: mdball2 1s infinite;
  animation: mdball2 1s infinite;
  background-color: #ff781d;
  -webkit-box-shadow: 30px 0 0 #fb3350;
  -moz-box-shadow: 30px 0 0 #fb3350;
  box-shadow: 30px 0 0 #fb3350;
}

@-webkit-keyframes lgrotate {
  0% {
    transform: rotate(0deg) scale(0.8);
    -webkit-transform: rotate(0deg) scale(0.8);
    -moz-transform: rotate(0deg) scale(0.8);
    -ms-transform: rotate(0deg) scale(0.8);
    -o-transform: rotate(0deg) scale(0.8);
  }
  50% {
    transform: rotate(360deg) scale(1.2);
    -webkit-transform: rotate(360deg) scale(1.2);
    -moz-transform: rotate(360deg) scale(1.2);
    -ms-transform: rotate(360deg) scale(1.2);
    -o-transform: rotate(360deg) scale(1.2);
  }
  100% {
    transform: rotate(720deg) scale(0.8);
    -webkit-transform: rotate(720deg) scale(0.8);
    -moz-transform: rotate(720deg) scale(0.8);
    -ms-transform: rotate(720deg) scale(0.8);
    -o-transform: rotate(720deg) scale(0.8);
  }
}
@-moz-keyframes lgrotate {
  0% {
    transform: rotate(0deg) scale(0.8);
    -webkit-transform: rotate(0deg) scale(0.8);
    -moz-transform: rotate(0deg) scale(0.8);
    -ms-transform: rotate(0deg) scale(0.8);
    -o-transform: rotate(0deg) scale(0.8);
  }
  50% {
    transform: rotate(360deg) scale(1.2);
    -webkit-transform: rotate(360deg) scale(1.2);
    -moz-transform: rotate(360deg) scale(1.2);
    -ms-transform: rotate(360deg) scale(1.2);
    -o-transform: rotate(360deg) scale(1.2);
  }
  100% {
    transform: rotate(720deg) scale(0.8);
    -webkit-transform: rotate(720deg) scale(0.8);
    -moz-transform: rotate(720deg) scale(0.8);
    -ms-transform: rotate(720deg) scale(0.8);
    -o-transform: rotate(720deg) scale(0.8);
  }
}
@-ms-keyframes lgrotate {
  0% {
    transform: rotate(0deg) scale(0.8);
    -webkit-transform: rotate(0deg) scale(0.8);
    -moz-transform: rotate(0deg) scale(0.8);
    -ms-transform: rotate(0deg) scale(0.8);
    -o-transform: rotate(0deg) scale(0.8);
  }
  50% {
    transform: rotate(360deg) scale(1.2);
    -webkit-transform: rotate(360deg) scale(1.2);
    -moz-transform: rotate(360deg) scale(1.2);
    -ms-transform: rotate(360deg) scale(1.2);
    -o-transform: rotate(360deg) scale(1.2);
  }
  100% {
    transform: rotate(720deg) scale(0.8);
    -webkit-transform: rotate(720deg) scale(0.8);
    -moz-transform: rotate(720deg) scale(0.8);
    -ms-transform: rotate(720deg) scale(0.8);
    -o-transform: rotate(720deg) scale(0.8);
  }
}
@keyframes lgrotate {
  0% {
    transform: rotate(0deg) scale(0.8);
    -webkit-transform: rotate(0deg) scale(0.8);
    -moz-transform: rotate(0deg) scale(0.8);
    -ms-transform: rotate(0deg) scale(0.8);
    -o-transform: rotate(0deg) scale(0.8);
  }
  50% {
    transform: rotate(360deg) scale(1.2);
    -webkit-transform: rotate(360deg) scale(1.2);
    -moz-transform: rotate(360deg) scale(1.2);
    -ms-transform: rotate(360deg) scale(1.2);
    -o-transform: rotate(360deg) scale(1.2);
  }
  100% {
    transform: rotate(720deg) scale(0.8);
    -webkit-transform: rotate(720deg) scale(0.8);
    -moz-transform: rotate(720deg) scale(0.8);
    -ms-transform: rotate(720deg) scale(0.8);
    -o-transform: rotate(720deg) scale(0.8);
  }
}
@-webkit-keyframes lgball1 {
  0% {
    -webkit-box-shadow: 60px 0 0 #ff781d;
    -moz-box-shadow: 60px 0 0 #ff781d;
    box-shadow: 60px 0 0 #ff781d;
  }
  50% {
    -webkit-box-shadow: 0 0 0 #ff781d;
    -moz-box-shadow: 0 0 0 #ff781d;
    box-shadow: 0 0 0 #ff781d;
    margin-bottom: 0;
    transform: translate(30px, 30px);
    -webkit-transform: translate(30px, 30px);
    -moz-transform: translate(30px, 30px);
    -ms-transform: translate(30px, 30px);
    -o-transform: translate(30px, 30px);
  }
  100% {
    -webkit-box-shadow: 60px 0 0 #ff781d;
    -moz-box-shadow: 60px 0 0 #ff781d;
    box-shadow: 60px 0 0 #ff781d;
    margin-bottom: 20px;
  }
}
@-moz-keyframes lgball1 {
  0% {
    -webkit-box-shadow: 60px 0 0 #ff781d;
    -moz-box-shadow: 60px 0 0 #ff781d;
    box-shadow: 60px 0 0 #ff781d;
  }
  50% {
    -webkit-box-shadow: 0 0 0 #ff781d;
    -moz-box-shadow: 0 0 0 #ff781d;
    box-shadow: 0 0 0 #ff781d;
    margin-bottom: 0;
    transform: translate(30px, 30px);
    -webkit-transform: translate(30px, 30px);
    -moz-transform: translate(30px, 30px);
    -ms-transform: translate(30px, 30px);
    -o-transform: translate(30px, 30px);
  }
  100% {
    -webkit-box-shadow: 60px 0 0 #ff781d;
    -moz-box-shadow: 60px 0 0 #ff781d;
    box-shadow: 60px 0 0 #ff781d;
    margin-bottom: 20px;
  }
}
@-ms-keyframes lgball1 {
  0% {
    -webkit-box-shadow: 60px 0 0 #ff781d;
    -moz-box-shadow: 60px 0 0 #ff781d;
    box-shadow: 60px 0 0 #ff781d;
  }
  50% {
    -webkit-box-shadow: 0 0 0 #ff781d;
    -moz-box-shadow: 0 0 0 #ff781d;
    box-shadow: 0 0 0 #ff781d;
    margin-bottom: 0;
    transform: translate(30px, 30px);
    -webkit-transform: translate(30px, 30px);
    -moz-transform: translate(30px, 30px);
    -ms-transform: translate(30px, 30px);
    -o-transform: translate(30px, 30px);
  }
  100% {
    -webkit-box-shadow: 60px 0 0 #ff781d;
    -moz-box-shadow: 60px 0 0 #ff781d;
    box-shadow: 60px 0 0 #ff781d;
    margin-bottom: 20px;
  }
}
@keyframes lgball1 {
  0% {
    -webkit-box-shadow: 60px 0 0 #ff781d;
    -moz-box-shadow: 60px 0 0 #ff781d;
    box-shadow: 60px 0 0 #ff781d;
  }
  50% {
    -webkit-box-shadow: 0 0 0 #ff781d;
    -moz-box-shadow: 0 0 0 #ff781d;
    box-shadow: 0 0 0 #ff781d;
    margin-bottom: 0;
    transform: translate(30px, 30px);
    -webkit-transform: translate(30px, 30px);
    -moz-transform: translate(30px, 30px);
    -ms-transform: translate(30px, 30px);
    -o-transform: translate(30px, 30px);
  }
  100% {
    -webkit-box-shadow: 60px 0 0 #ff781d;
    -moz-box-shadow: 60px 0 0 #ff781d;
    box-shadow: 60px 0 0 #ff781d;
    margin-bottom: 20px;
  }
}
@-webkit-keyframes lgball2 {
  0% {
    -webkit-box-shadow: 60px 0 0 #fb3350;
    -moz-box-shadow: 60px 0 0 #fb3350;
    box-shadow: 60px 0 0 #fb3350;
  }
  50% {
    -webkit-box-shadow: 0 0 0 #fb3350;
    -moz-box-shadow: 0 0 0 #fb3350;
    box-shadow: 0 0 0 #fb3350;
    margin-top: -40px;
    transform: translate(30px, 30px);
    -webkit-transform: translate(30px, 30px);
    -moz-transform: translate(30px, 30px);
    -ms-transform: translate(30px, 30px);
    -o-transform: translate(30px, 30px);
  }
  100% {
    -webkit-box-shadow: 60px 0 0 #fb3350;
    -moz-box-shadow: 60px 0 0 #fb3350;
    box-shadow: 60px 0 0 #fb3350;
    margin-top: 0;
  }
}
@-moz-keyframes lgball2 {
  0% {
    -webkit-box-shadow: 60px 0 0 #fb3350;
    -moz-box-shadow: 60px 0 0 #fb3350;
    box-shadow: 60px 0 0 #fb3350;
  }
  50% {
    -webkit-box-shadow: 0 0 0 #fb3350;
    -moz-box-shadow: 0 0 0 #fb3350;
    box-shadow: 0 0 0 #fb3350;
    margin-top: -40px;
    transform: translate(30px, 30px);
    -webkit-transform: translate(30px, 30px);
    -moz-transform: translate(30px, 30px);
    -ms-transform: translate(30px, 30px);
    -o-transform: translate(30px, 30px);
  }
  100% {
    -webkit-box-shadow: 60px 0 0 #fb3350;
    -moz-box-shadow: 60px 0 0 #fb3350;
    box-shadow: 60px 0 0 #fb3350;
    margin-top: 0;
  }
}
@-ms-keyframes lgball2 {
  0% {
    -webkit-box-shadow: 60px 0 0 #fb3350;
    -moz-box-shadow: 60px 0 0 #fb3350;
    box-shadow: 60px 0 0 #fb3350;
  }
  50% {
    -webkit-box-shadow: 0 0 0 #fb3350;
    -moz-box-shadow: 0 0 0 #fb3350;
    box-shadow: 0 0 0 #fb3350;
    margin-top: -40px;
    transform: translate(30px, 30px);
    -webkit-transform: translate(30px, 30px);
    -moz-transform: translate(30px, 30px);
    -ms-transform: translate(30px, 30px);
    -o-transform: translate(30px, 30px);
  }
  100% {
    -webkit-box-shadow: 60px 0 0 #fb3350;
    -moz-box-shadow: 60px 0 0 #fb3350;
    box-shadow: 60px 0 0 #fb3350;
    margin-top: 0;
  }
}
@keyframes lgball2 {
  0% {
    -webkit-box-shadow: 60px 0 0 #fb3350;
    -moz-box-shadow: 60px 0 0 #fb3350;
    box-shadow: 60px 0 0 #fb3350;
  }
  50% {
    -webkit-box-shadow: 0 0 0 #fb3350;
    -moz-box-shadow: 0 0 0 #fb3350;
    box-shadow: 0 0 0 #fb3350;
    margin-top: -40px;
    transform: translate(30px, 30px);
    -webkit-transform: translate(30px, 30px);
    -moz-transform: translate(30px, 30px);
    -ms-transform: translate(30px, 30px);
    -o-transform: translate(30px, 30px);
  }
  100% {
    -webkit-box-shadow: 60px 0 0 #fb3350;
    -moz-box-shadow: 60px 0 0 #fb3350;
    box-shadow: 60px 0 0 #fb3350;
    margin-top: 0;
  }
}
.loading-size-lg .loader {
  -webkit-animation: lgrotate 1s infinite;
  -moz-animation: lgrotate 1s infinite;
  -ms-animation: lgrotate 1s infinite;
  -o-animation: lgrotate 1s infinite;
  animation: lgrotate 1s infinite;
  height: 100px;
  width: 100px;
  margin-bottom: 1.2rem;
}
.loading-size-lg .loader:before,
.loading-size-lg .loader:after {
  border-radius: 50%;
  content: "";
  display: block;
  height: 40px;
  width: 40px;
}
.loading-size-lg .loader:before {
  -webkit-box-shadow: 60px 0 0 #ff781d;
  -moz-box-shadow: 60px 0 0 #ff781d;
  box-shadow: 60px 0 0 #ff781d;
  margin-bottom: 20px;
  -webkit-animation: lgball1 1s infinite;
  -moz-animation: lgball1 1s infinite;
  -ms-animation: lgball1 1s infinite;
  -o-animation: lgball1 1s infinite;
  animation: lgball1 1s infinite;
  background-color: #fb3350;
}
.loading-size-lg .loader:after {
  -webkit-animation: lgball2 1s infinite;
  -moz-animation: lgball2 1s infinite;
  -ms-animation: lgball2 1s infinite;
  -o-animation: lgball2 1s infinite;
  animation: lgball2 1s infinite;
  background-color: #ff781d;
  -webkit-box-shadow: 60px 0 0 #fb3350;
  -moz-box-shadow: 60px 0 0 #fb3350;
  box-shadow: 60px 0 0 #fb3350;
}

.spaced-container {
  padding: 2.4rem 0;
}

.absolute-container {
  position: absolute;
  z-index: 1000;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.4);
}

.loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.absolute-loading {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}

.inline-loading {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  align-self: center;
  justify-self: center;
}
.inline-loading .lds-ring {
  display: inline-block;
  position: relative;
  width: 2rem;
  height: 2rem;
}
.inline-loading .lds-ring div {
  width: 1.5rem;
  display: block;
  height: 1.5rem;
  margin: 0.2rem;
  position: absolute;
  box-sizing: border-box;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  border: 0.2rem solid;
  border-color: #ff781d transparent transparent transparent;
  -webkit-animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  -moz-animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  -ms-animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  -o-animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
}
.inline-loading .lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}
.inline-loading .lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}
.inline-loading .lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.wrapper {
  display: block;
  margin-bottom: 1.2rem;
  position: relative;
}
.wrapper .input-label {
  position: relative;
  width: 100%;
  padding-bottom: 0.4rem;
  display: block;
  font-size: 1.6rem;
  color: #6a6a6a;
  line-height: 1.9rem;
}
.wrapper .common-input {
  width: 100%;
  border: none;
  font-size: 1.6rem;
  padding: 0.8rem 1.2rem;
  color: #171717;
  height: 4rem;
  background-color: #f3f6f7;
}
.wrapper .common-input::placeholder {
  font-size: 1.6rem;
  color: #6a6a6a;
  font-style: italic;
}
.wrapper .common-input:focus, .wrapper .common-input:focus-visible {
  outline-style: solid !important;
  outline-width: 2px !important;
}
.wrapper .input-description {
  font-size: 1.6rem;
  font-style: italic;
  color: #999999;
}

.wrapper-disabled {
  cursor: not-allowed;
}
.wrapper-disabled .input-label {
  cursor: not-allowed;
}
.wrapper-disabled .common-input {
  cursor: not-allowed;
  background-color: #d6d6d6;
  color: #b2b2b2;
}

.dark-theme .wrapper .input-label,
.dark-frame .wrapper .input-label,
.dark-app .wrapper .input-label {
  color: #e7ecef;
}
.dark-theme .wrapper .common-input,
.dark-frame .wrapper .common-input,
.dark-app .wrapper .common-input {
  background-color: #585858;
  color: #e7ecef;
}
.dark-theme .wrapper .common-input::placeholder,
.dark-theme .wrapper .common-input .placeholder,
.dark-frame .wrapper .common-input::placeholder,
.dark-frame .wrapper .common-input .placeholder,
.dark-app .wrapper .common-input::placeholder,
.dark-app .wrapper .common-input .placeholder {
  color: #b3b3b3 !important;
}

.checkbox-container {
  display: flex;
  flex-direction: row-reverse;
  justify-content: flex-end;
  align-items: center;
  position: relative;
  margin-bottom: 0;
  cursor: pointer;
  font-size: 1.6rem;
  color: #6a6a6a;
  height: 3rem;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.checkbox-container .checkbox-input {
  position: absolute;
  opacity: 0;
  left: 2rem;
  top: 3rem;
  cursor: pointer;
  height: 0;
  width: 0;
}
.checkbox-container .check-mark {
  border: 2px solid #171717;
  top: 0;
  left: 0;
  margin-right: 1.2rem;
  min-height: 1.6rem;
  height: 1.6rem;
  min-width: 1.6rem;
  width: 1.6rem;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
  position: relative;
}
.checkbox-container .checkmark {
  position: absolute;
  top: -0.7rem;
  left: -0.2rem;
}
.checkbox-container .checkbox-input:checked ~ .check-mark::after {
  display: block;
}
.checkbox-container .checkbox-link {
  text-decoration: none;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
  margin-bottom: 0;
  padding-bottom: 0;
}

.dark-theme .checkbox-container,
.dark-frame .checkbox-container,
.dark-app .checkbox-container {
  color: #e7ecef;
}
.dark-theme .checkbox-container .check-mark,
.dark-frame .checkbox-container .check-mark,
.dark-app .checkbox-container .check-mark {
  background-color: #333333;
  border: 2px solid #d6d6d6 !important;
}
.dark-theme .checkbox-container .check-mark::after,
.dark-frame .checkbox-container .check-mark::after,
.dark-app .checkbox-container .check-mark::after {
  border-color: #d6d6d6 !important;
}

.wrapper-disabled {
  cursor: not-allowed;
}
.wrapper-disabled .checkbox-container {
  cursor: not-allowed;
  color: #b2b2b2;
}
.wrapper-disabled .checkbox-container .check-mark {
  background-color: #d6d6d6;
  border: 2px solid #d6d6d6;
}

.wrapper .text-input-unit {
  position: absolute;
  font-size: 1.2rem;
  color: #6a6a6a;
  width: 4rem;
  height: 4rem;
  top: 2.3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #e7ecef;
  right: 0;
}
.wrapper .text-input-unit-without-label {
  top: 0;
}
.wrapper .clickable-unit {
  cursor: pointer;
}
.wrapper .search-input {
  padding-left: 4.8rem;
}
.wrapper .search-icon {
  left: 0;
  right: unset;
  pointer-events: none;
  background: none !important;
}
.wrapper .input-label-with-unit {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.wrapper .input-label-with-unit .input-controls {
  display: flex;
  align-items: center;
}
.wrapper .input-label-with-unit .input-controls__size {
  margin-left: 0.4rem;
  cursor: pointer;
}
.wrapper .input-label-with-unit .input-controls__size:hover {
  color: #171717;
}
.wrapper .input-label-with-unit .input-controls__size.active-size {
  color: #171717;
  font-weight: 500;
}

.select {
  position: relative;
}
.select .select-input {
  position: relative;
  color: #171717;
}
.select .select-input__controls {
  position: absolute;
  color: #999999;
  right: 0;
  height: 4rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  top: 0;
  padding-right: 0.8rem;
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Old versions of Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently supported by Chrome, Opera and Firefox */
}
.select .select-input__controls--separator {
  padding: 0 0.4rem;
}
.select .select-input__controls--icon {
  cursor: pointer;
}
.select .select-input__controls--icon:hover {
  color: #ff781d;
}
.select .select-input__search {
  border: none;
  display: flex;
  align-items: center;
  height: 4rem;
  background-color: #f3f6f7;
  padding: 0.4rem 6.4rem 0.4rem 1.6rem;
  font-size: 1.6rem;
  border-width: 2px;
  border-style: solid;
  border-color: transparent;
  color: #171717;
  width: 100%;
}
.select .select-input__search::placeholder {
  color: #6a6a6a;
  font-weight: 400;
  font-style: italic;
}
.select .select-input__list {
  left: 0;
  right: 0;
  z-index: 101;
  width: 100%;
  min-width: 25rem;
  position: absolute;
  margin-top: 0.4rem;
  background-color: #ffffff;
  -webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  -moz-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
}
.select .select-input__list--last-search-results {
  padding: 0.8rem 1.2rem;
  font-style: italic;
  font-size: 1.1rem;
  text-align: center;
  color: #999999;
  border-bottom: 1px solid #e0e7e9;
}
.select .select-input__list--loading {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  padding: 0.8rem 1.2rem 0;
}
.select .select-input__list--loading .search-loading-text {
  width: 100%;
  text-align: center;
  padding-bottom: 0.8rem;
}
.select .select-input__list--input-overlay {
  pointer-events: none;
  background-color: transparent;
  height: 4.5rem;
  pointer-events: none;
  background: none !important;
}
.select .select-input__list--scrollable {
  max-height: 14rem;
  overflow-y: auto;
  pointer-events: all;
}
.select .select-input__list--item {
  cursor: pointer;
  padding: 1.2rem 1.2rem;
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Old versions of Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently supported by Chrome, Opera and Firefox */
}
.select .select-input__list--item.--active {
  background-color: #a93e73;
  color: #e7ecef;
}
.select .select-input__list--item:hover {
  background-color: #393939;
  color: #e7ecef;
}
.select .select-input__list--item.--selected {
  background-color: #ff781d;
  color: #e7ecef;
  font-weight: 500;
}

.dark-theme .select-input,
.dark-frame .select-input,
.dark-app .select-input {
  color: #e7ecef;
}
.dark-theme .select-input__search,
.dark-frame .select-input__search,
.dark-app .select-input__search {
  background-color: #585858;
  color: #e7ecef;
}
.dark-theme .select-input__search::placeholder,
.dark-frame .select-input__search::placeholder,
.dark-app .select-input__search::placeholder {
  color: #b0b0b0;
}
.dark-theme .select-input__list,
.dark-frame .select-input__list,
.dark-app .select-input__list {
  background-color: #585858;
}
.dark-theme .select-input__list--last-search-results,
.dark-frame .select-input__list--last-search-results,
.dark-app .select-input__list--last-search-results {
  border-bottom-color: #4b4b4b;
}
.dark-theme .select-input__list--item.--active,
.dark-frame .select-input__list--item.--active,
.dark-app .select-input__list--item.--active {
  background-color: #333333;
}
.dark-theme .select-input__list--item.--selected,
.dark-frame .select-input__list--item.--selected,
.dark-app .select-input__list--item.--selected {
  background-color: #171717;
}

.select-disabled .select-input__search {
  background-color: #333333;
  cursor: not-allowed;
  color: #6a6a6a;
}
.select-disabled .select-input__search::placeholder {
  color: #6a6a6a;
}
.select-disabled .select-input__controls {
  color: #6a6a6a;
}
.select-disabled .select-input__controls--icon:hover {
  cursor: not-allowed;
  color: #6a6a6a;
}

.search-input {
  position: relative;
}
.search-input .search-option-list {
  left: 0;
  right: 0;
  z-index: 5;
  top: 6.5rem;
  display: block;
  position: absolute;
  border-style: solid;
  border-width: 0.2rem;
  background-color: #ffffff;
}
.search-input .search-option-list__header {
  width: 100%;
  font-weight: 600;
  font-size: 1.2rem;
  text-align: center;
  border-bottom: 1px solid #e7ecef;
  padding: 0.2rem 1.2rem;
}
.search-input .search-option-list__scroll {
  overflow-y: auto;
  max-height: 15rem;
}
.search-input .search-option-list__scroll .search-option {
  width: 100%;
  font-size: 1.4rem;
  background-color: transparent;
  border-bottom: 1px solid #e7ecef;
  padding: 0.8rem 1.2rem;
}
.search-input .search-option-list__scroll .search-option:last-of-type {
  border-bottom: none;
}

.global__rounded-corners .search-option-list {
  padding: 0.4rem 0;
  -webkit-border-radius: 1rem;
  -moz-border-radius: 1rem;
  border-radius: 1rem;
}

.modal-container {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: -moz-radial-gradient(rgba(60, 60, 60, 0.9) 35%, rgba(0, 0, 0, 0.9) 100%) !important;
  background: -webkit-gradient(rgba(60, 60, 60, 0.9) 35%, rgba(0, 0, 0, 0.9) 100%) !important;
  background: -webkit-radial-gradient(rgba(60, 60, 60, 0.9) 35%, rgba(0, 0, 0, 0.9) 100%) !important;
  background: -o-radial-gradient(rgba(60, 60, 60, 0.9) 35%, rgba(0, 0, 0, 0.9) 100%) !important;
  background: -ms-radial-gradient(rgba(60, 60, 60, 0.9) 35%, rgba(0, 0, 0, 0.9) 100%) !important;
  background: radial-gradient(rgba(60, 60, 60, 0.9) 35%, rgba(0, 0, 0, 0.9) 100%) !important;
  z-index: 10000;
  display: flex;
  padding: 0 2.4rem;
  justify-content: center;
  align-items: center;
  backdrop-filter: blur(4px);
}
.modal-container .modal {
  display: flex;
  max-height: 100%;
  flex-direction: column;
  background-color: #ffffff;
}
.modal-container .modal__heading {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #ffffff;
  position: relative;
  z-index: 10;
  border-bottom: 1px solid #e7ecef;
  height: 50px;
}
.modal-container .modal__heading--content {
  padding: 0.8rem 1.2rem;
  font-size: 1.8rem;
  font-weight: 500;
}
.modal-container .modal__heading--close {
  height: 49px;
  padding: 0 1.2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.modal-container .modal__heading--close:hover {
  background-color: #e0e7e9;
}
.modal-container .modal__content {
  flex-grow: 1;
  display: flex;
  overflow-y: auto;
  position: relative;
  padding: 2.4rem;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.modal-container .modal__controls {
  display: flex;
  z-index: 10;
  align-items: center;
  position: relative;
  justify-content: space-between;
}
.modal-container .modal__controls--button {
  border: none !important;
  height: 50px;
  width: 50%;
}
.modal-container .modal__controls .btnv2 {
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
  grid-template-columns: auto 4rem;
}
.modal-container .modal__controls .btnv2__icon {
  justify-content: center;
  padding-right: 0.8rem;
}
.modal-container .modal__controls .btnv2__icon--triangle {
  border-top-width: 5rem;
}
.modal-container .modal__controls .btnv2.--primary {
  -webkit-border-top-right-radius: 0;
  -webkit-border-bottom-right-radius: 1rem;
  -webkit-border-bottom-left-radius: 0;
  -webkit-border-top-left-radius: 0;
  -moz-border-radius-topright: 0;
  -moz-border-radius-bottomright: 1rem;
  -moz-border-radius-bottomleft: 0;
  -moz-border-radius-topleft: 0;
  border-top-right-radius: 0;
  border-bottom-right-radius: 1rem;
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
  -moz-background-clip: padding;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
}
.modal-container .modal__controls .btnv2.--primary .btnv2__icon {
  -webkit-border-top-right-radius: 0;
  -webkit-border-bottom-right-radius: 1rem;
  -webkit-border-bottom-left-radius: 0;
  -webkit-border-top-left-radius: 0;
  -moz-border-radius-topright: 0;
  -moz-border-radius-bottomright: 1rem;
  -moz-border-radius-bottomleft: 0;
  -moz-border-radius-topleft: 0;
  border-top-right-radius: 0;
  border-bottom-right-radius: 1rem;
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
  -moz-background-clip: padding;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
}
.modal-container .modal__controls .btnv2.--secondary {
  -webkit-border-top-right-radius: 0;
  -webkit-border-bottom-right-radius: 0;
  -webkit-border-bottom-left-radius: 1rem;
  -webkit-border-top-left-radius: 0;
  -moz-border-radius-topright: 0;
  -moz-border-radius-bottomright: 0;
  -moz-border-radius-bottomleft: 1rem;
  -moz-border-radius-topleft: 0;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 1rem;
  border-top-left-radius: 0;
  -moz-background-clip: padding;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
}
.modal-container .modal__controls .btnv2.--secondary .btnv2__icon {
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
}
.modal-container .modal.rounded-modal {
  -webkit-border-radius: 1rem;
  -moz-border-radius: 1rem;
  border-radius: 1rem;
}
.modal-container .modal.rounded-modal .modal__heading {
  -webkit-border-top-right-radius: 1rem;
  -webkit-border-bottom-right-radius: 0;
  -webkit-border-bottom-left-radius: 0;
  -webkit-border-top-left-radius: 1rem;
  -moz-border-radius-topright: 1rem;
  -moz-border-radius-bottomright: 0;
  -moz-border-radius-bottomleft: 0;
  -moz-border-radius-topleft: 1rem;
  border-top-right-radius: 1rem;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
  border-top-left-radius: 1rem;
  -moz-background-clip: padding;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
}
.modal-container .modal.rounded-modal .modal__heading--close {
  -webkit-border-top-right-radius: 1rem;
  -webkit-border-bottom-right-radius: 0;
  -webkit-border-bottom-left-radius: 0;
  -webkit-border-top-left-radius: 0;
  -moz-border-radius-topright: 1rem;
  -moz-border-radius-bottomright: 0;
  -moz-border-radius-bottomleft: 0;
  -moz-border-radius-topleft: 0;
  border-top-right-radius: 1rem;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
  -moz-background-clip: padding;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
}
.modal-container .modal.rounded-modal .modal__controls {
  -webkit-border-top-right-radius: 0;
  -webkit-border-bottom-right-radius: 1rem;
  -webkit-border-bottom-left-radius: 1rem;
  -webkit-border-top-left-radius: 0;
  -moz-border-radius-topright: 0;
  -moz-border-radius-bottomright: 1rem;
  -moz-border-radius-bottomleft: 1rem;
  -moz-border-radius-topleft: 0;
  border-top-right-radius: 0;
  border-bottom-right-radius: 1rem;
  border-bottom-left-radius: 1rem;
  border-top-left-radius: 0;
  -moz-background-clip: padding;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
}
.modal-container .modal-passive.rounded-modal .modal__content {
  -webkit-border-top-right-radius: 0;
  -webkit-border-bottom-right-radius: 1rem;
  -webkit-border-bottom-left-radius: 1rem;
  -webkit-border-top-left-radius: 0;
  -moz-border-radius-topright: 0;
  -moz-border-radius-bottomright: 1rem;
  -moz-border-radius-bottomleft: 1rem;
  -moz-border-radius-topleft: 0;
  border-top-right-radius: 0;
  border-bottom-right-radius: 1rem;
  border-bottom-left-radius: 1rem;
  border-top-left-radius: 0;
  -moz-background-clip: padding;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
}
.modal-container .modal-allow-overflow .modal__content {
  overflow-y: unset;
}
.modal-container .modal-allow-overflow .modal__controls {
  z-index: unset;
}
.modal-container .modal-danger .modal__controls--button:last-of-type {
  background-color: #fb3350 !important;
  color: #e7ecef !important;
}
.modal-container .dark-theme .modal__heading,
.modal-container .dark-theme .modal__content,
.modal-container .dark-theme .modal__controls {
  background-color: #393939;
}
.modal-container .dark-theme .modal__heading {
  border-bottom-color: #585858;
}
.modal-container .dark-theme .modal__heading--close:hover {
  background-color: #585858;
}
.modal-container .modal-XS {
  max-width: 320px;
  width: 100%;
}
.modal-container .modal-XS .modal__content {
  max-height: 80vh;
}
.modal-container .modal-SM {
  max-width: 576px;
  width: 100%;
}
.modal-container .modal-SM .modal__content {
  max-height: 80vh;
}
.modal-container .modal-MD {
  max-width: 768px;
  width: 100%;
  height: 50vh;
}
.modal-container .modal-LG {
  max-width: 992px;
  width: 100%;
  height: 80vh;
}
.modal-container .modal-LG .modal__content {
  max-height: unset;
}
@media screen and (max-width: 576px) {
  .modal-container {
    padding: 0;
  }
  .modal-container .modal-XS,
.modal-container .modal-SM,
.modal-container .modal-MD,
.modal-container .modal-LG {
    height: 100vh;
    max-width: 100%;
  }
  .modal-container .modal-XS .modal__content,
.modal-container .modal-SM .modal__content,
.modal-container .modal-MD .modal__content,
.modal-container .modal-LG .modal__content {
    max-height: unset;
  }
}

.navbar {
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  z-index: 103;
  height: 5rem;
  display: grid;
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Old versions of Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently supported by Chrome, Opera and Firefox */
  font-family: inherit;
  background-color: #e7ecef;
  -webkit-transition: all 200ms linear;
  -moz-transition: all 200ms linear;
  -ms-transition: all 200ms linear;
  -o-transition: all 200ms linear;
  transition: all 200ms linear;
}
@supports not (position: sticky) {
  .navbar {
    position: fixed;
  }
}
.navbar__languages {
  display: flex;
  font-size: 1.6rem;
  position: relative;
  align-items: center;
}
.navbar__languages--button {
  padding: 0.4rem 0.8rem;
  font-size: 1.6rem;
  border: none;
  background-color: transparent;
  margin: 0;
  color: #999999;
  cursor: pointer;
}
.navbar__languages--button.active-language {
  cursor: default;
  font-weight: 600;
  color: #171717;
}
.navbar__languages--button:hover {
  -webkit-filter: invert(0.1);
  -ms-filter: invert(0.1);
  filter: invert(0.1);
}
.navbar__languages--separator {
  color: #999999;
}
.navbar__meta {
  width: 5rem;
  height: 5rem;
  display: none;
  cursor: pointer;
  align-items: center;
  justify-content: center;
}
.navbar__meta:hover {
  -webkit-filter: invert(0.2);
  -ms-filter: invert(0.2);
  filter: invert(0.2);
}
.navbar__links {
  display: flex;
  position: relative;
  align-items: center;
  padding-left: 1.2rem;
  justify-content: flex-start;
}
.navbar__links--element {
  padding: 1.2rem;
  position: relative;
}
.navbar__logo {
  display: flex;
  overflow: hidden;
  align-items: stretch;
  justify-content: flex-start;
}
.navbar__logo--image-container {
  display: flex;
  flex-shrink: 0;
  max-width: 100%;
  max-height: 100%;
  align-items: center;
  justify-content: flex-start;
}
.navbar__logo--image-container--image {
  max-height: 4.2rem;
  width: auto;
  max-width: 100%;
  height: auto;
}
.navbar__logo--name {
  padding-left: 0.8rem;
  align-self: center;
  font-size: 1.8rem;
  text-align: left;
  font-weight: 500;
  flex-grow: 1;
}
.navbar__sidemenu {
  top: 0;
  left: 0;
  bottom: 0;
  display: none;
  z-index: 20000;
  position: fixed;
  overflow-y: auto;
  width: 25rem;
  flex-direction: column;
  background-color: #ffffff;
  transform: translateX(-100%);
  -webkit-transform: translateX(-100%);
  -moz-transform: translateX(-100%);
  -ms-transform: translateX(-100%);
  -o-transform: translateX(-100%);
  -webkit-transition: transform 200ms linear;
  -moz-transition: transform 200ms linear;
  -ms-transition: transform 200ms linear;
  -o-transition: transform 200ms linear;
  transition: transform 200ms linear;
}
.navbar__sidemenu .navbar__logo {
  max-width: unset;
  max-height: unset;
  padding: 1.2rem;
}
.navbar__sidemenu .navbar__logo--image-container {
  max-height: unset;
  max-width: 100%;
  display: flex;
}
.navbar__sidemenu .navbar__links {
  padding: 0;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.navbar__backdrop {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: none;
  z-index: 10000;
  position: fixed;
  background-color: rgba(0, 0, 0, 0.7);
}
.navbar.--LOGO_AND_NAME {
  grid-template-columns: 25rem auto 9rem;
}
.navbar.--WITH_LOGO {
  grid-template-columns: 10rem auto 9rem;
}
.navbar.--WITH_LOGO .navbar__logo {
  justify-content: center;
}
.navbar.--WITH_NAME {
  grid-template-columns: 20rem 1fr 9rem;
}
.navbar.--APP_NAVBAR {
  padding-right: 1.6rem !important;
  grid-template-columns: auto 8rem !important;
}
.navbar.--APP_NAVBAR .navbar__languages {
  display: flex !important;
  justify-content: flex-end;
}
.navbar.--APP_NAVBAR .navbar__logo {
  justify-content: flex-start !important;
  padding-left: 2.4rem !important;
}
.navbar.navbar-sm .navbar__languages, .navbar.navbar-xs .navbar__languages {
  justify-content: flex-end;
}
.navbar.navbar-sm .navbar__languages--button, .navbar.navbar-xs .navbar__languages--button {
  padding: 0.4rem;
}
.navbar.navbar-sm .navbar__languages--button:first-of-type, .navbar.navbar-xs .navbar__languages--button:first-of-type {
  padding-left: 0;
}
.navbar.navbar-sm .navbar__meta, .navbar.navbar-xs .navbar__meta {
  display: flex;
}
.navbar.navbar-sm .navbar__links, .navbar.navbar-xs .navbar__links {
  display: none;
}
.navbar.navbar-sm .navbar__sidemenu, .navbar.navbar-xs .navbar__sidemenu {
  display: flex;
}
.navbar.navbar-sm.--WITH_LOGO, .navbar.navbar-xs.--WITH_LOGO {
  grid-template-columns: 10rem auto 5rem;
}
.navbar.navbar-sm.--WITH_NAME, .navbar.navbar-xs.--WITH_NAME {
  grid-template-columns: 15rem 1fr 5rem;
}
.navbar.navbar-sm.--LOGO_AND_NAME, .navbar.navbar-xs.--LOGO_AND_NAME {
  grid-template-columns: 20rem auto 5rem;
}
.navbar.navbar-sm.--LOGO_AND_NAME .navbar__logo, .navbar.navbar-xs.--LOGO_AND_NAME .navbar__logo {
  padding: 0.4rem 0.8rem;
}
.navbar.navbar-sm.navbar-opened .navbar__sidemenu, .navbar.navbar-xs.navbar-opened .navbar__sidemenu {
  transform: translateX(0%);
  -webkit-transform: translateX(0%);
  -moz-transform: translateX(0%);
  -ms-transform: translateX(0%);
  -o-transform: translateX(0%);
}
.navbar.navbar-sm.navbar-opened .navbar__sidemenu .navbar__links, .navbar.navbar-xs.navbar-opened .navbar__sidemenu .navbar__links {
  display: flex;
}
.navbar.navbar-sm.navbar-opened .navbar__sidemenu .navbar__links--element, .navbar.navbar-xs.navbar-opened .navbar__sidemenu .navbar__links--element {
  width: 100%;
}
.navbar.navbar-sm.navbar-opened .navbar__sidemenu .navbar__logo, .navbar.navbar-xs.navbar-opened .navbar__sidemenu .navbar__logo {
  flex-direction: column;
}
.navbar.navbar-sm.navbar-opened .navbar__sidemenu .navbar__logo--image-container, .navbar.navbar-xs.navbar-opened .navbar__sidemenu .navbar__logo--image-container {
  justify-content: center;
  margin-bottom: 1.2rem;
}
.navbar.navbar-sm.navbar-opened .navbar__sidemenu .navbar__logo--name, .navbar.navbar-xs.navbar-opened .navbar__sidemenu .navbar__logo--name {
  display: block;
}
.navbar.navbar-sm.navbar-opened .navbar__backdrop, .navbar.navbar-xs.navbar-opened .navbar__backdrop {
  display: block;
}
.navbar.navbar-xs.--LOGO_AND_NAME {
  grid-template-columns: 15rem auto 5rem;
}

.dark-navbar {
  background-color: #333333;
  color: #e7ecef;
}
.dark-navbar .navbar__sidemenu {
  background-color: #333333;
}
.dark-navbar .navbar__languages--button {
  color: #6a6a6a;
}
.dark-navbar .navbar__languages--button.active-language {
  color: #e7ecef;
}
.dark-navbar .navbar__meta--button {
  color: #999999;
}

.footer {
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 100;
  display: grid;
  position: absolute;
  align-items: center;
  padding: 6.4rem;
  background-color: #ffffff;
  grid-template-columns: repeat(2, 1fr);
}
.footer__logo {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.footer__logo--image {
  max-width: 20rem;
  max-height: 20rem;
  height: auto;
  width: auto;
}
.footer__content {
  display: flex;
  align-items: center;
  row-gap: 0.8rem;
  flex-direction: column;
  justify-content: center;
  grid-template-columns: repeat(2, 1fr);
}
.footer__content--block {
  display: flex;
  flex-direction: column;
}
.footer__content--block--icon {
  text-align: center;
  border-bottom: 1px solid #e7ecef;
  padding-bottom: 0.4rem;
}
.footer__content--block--data {
  padding-top: 1.2rem;
  text-align: center;
}
.footer__content--logo {
  display: flex;
  align-items: center;
  justify-content: center;
}
.footer__content--logo--onemin {
  margin-left: 0.8rem;
  max-height: 2rem;
  width: auto;
}
.footer__content--policy-files {
  display: flex;
  column-gap: 1.2rem;
}
.footer__content--policy-files .policy-link {
  color: unset;
}

.footer-sm {
  padding: 4rem 2.4rem;
  grid-template-columns: 1fr 35rem;
}

.footer-xs {
  grid-template-columns: 1fr;
  padding: 2.4rem;
  row-gap: 2.4rem;
  column-gap: 0;
}
.footer-xs .footer__content--logo {
  row-gap: 0.4rem;
  flex-direction: column;
}
.footer-xs .footer__content--policy-files {
  flex-direction: column;
  row-gap: 0.4rem;
}

.dark-footer {
  background-color: #333333;
  color: #e7ecef;
}
.dark-footer .footer__top {
  color: #999999;
}
.dark-footer .footer__top--block--icon {
  border-bottom-color: #585858;
}
